import {
  IContextData,
  IDBSnapshot,
  IGroupData,
  IRoom,
} from "../pages/accommodation/rooming-list";
import { fireStore } from "./firebase";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";

const setDataToFireStore = async ({
  code,
  ...dataToUpdate
}: IGroupData): Promise<boolean> => {
  try {
    const userRef = doc(fireStore, "users", code);
    if (!userRef) return false;

    const userSnap = await getDoc(userRef);

    await setDoc(userRef, { ...userSnap.data(), code, ...dataToUpdate });

    await Promise.all(
      dataToUpdate.accommodation.map(async (roomId) => {
        const roomRef = doc(fireStore, "rooms", roomId);

        if (!roomRef) return;

        const roomSnap = await getDoc(roomRef);

        await setDoc(roomRef, { ...roomSnap.data(), isAvailable: false });
      })
    );

    return true;
  } catch (error) {
    console.error(
      "Firestore failed to post message. Error from firebase:",
      error
    );

    return false;
  }
};

const sendEmailAndSaveToGoogleSheet = async (data: IGroupData) => {
  try {
    if (process.env.REACT_APP_PRODUCTION_ENVIRONMENT !== "true") return;

    const response = await fetch(
      "https://hook.eu2.make.com/qa1vpv2grtt69lu1sxsgjkrvmhiifu40",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) throw new Error(`HTTP error! State: ${response.status}`);
  } catch (error) {
    console.error("Send email and save to the google sheet failed", error);
  }
};

export const submitForm = async (data: IGroupData): Promise<boolean> => {
  const isStoredInDb = await setDataToFireStore(data);
  // await sendEmailAndSaveToGoogleSheet(data);

  return isStoredInDb;
};

export const getGroups = async (code: string): Promise<IGroupData | null> => {
  try {
    const docRef = doc(fireStore, "users", code.toUpperCase());
    const docSnap = await getDoc(docRef);
    const groupData = (docSnap.data() as IGroupData) || null;

    return groupData;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getAllGroups = async (): Promise<IGroupData[]> => {
  try {
    const querySnapshot = await getDocs(collection(fireStore, "users"));

    if (querySnapshot.empty) {
      console.log("No data founded");
      return [];
    }

    return querySnapshot.docs.map(
      (doc): IGroupData => doc.data() as IGroupData
    );
  } catch (error) {
    console.error(error);

    return [];
  }
};

export const getRooms = async (): Promise<IRoom[]> => {
  try {
    const querySnapshot = await getDocs(collection(fireStore, "rooms"));

    return querySnapshot.docs.map((doc): IRoom => doc.data() as IRoom);
  } catch (error) {
    console.error(error);

    return [];
  }
};

export const getAll = async (): Promise<IDBSnapshot> => {
  const groups = await getAllGroups();
  const rooms = await getRooms();

  return { groups, rooms };
};

export const getDataForForm = async (code: string): Promise<IContextData> => {
  const group = await getGroups(code);
  const rooms = await getRooms();

  return { group, rooms };
};
