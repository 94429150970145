import React, { ReactNode } from 'react'
import htmlLinkCss from './html-link-css'

type Props = {
  children: ReactNode,
  href: string,
}

const HtmlLink: React.FC<Props> = ({href, children}) => (
    <a href={href} className={htmlLinkCss.wrapper()} target="_blank" rel="noreferrer">
      {children}
    </a>
)

export default HtmlLink
