import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const giftsCss = {
  container: () => css({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  }),

  wrapper: () => css({
    display: "flex",
    justifyContent: "center",
  }),

  info: () => css({
    width: 800,
    maxWidth: "100%",
    textAlign: "center",
    fontSize: 18,

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 16,
    },
  }),

  whiteSpace: () => css({
    // NOTE is set to display the footer on the bottom edge of the page
    flex: 1,
  }),
}

export default giftsCss
