import React, { ReactNode } from 'react'
import pageTitleCss from './page-title-css'
import Section from '../section/section'

type Props = {
  children: ReactNode,
  marginTop?: boolean,
  isBackgroundBlue?: boolean,
}

const PageTitle: React.FC<Props> = ({ children, marginTop, isBackgroundBlue }) => (
  <Section isBackgroundBlue={isBackgroundBlue}>
    <div className={pageTitleCss.pageTitle({ marginTop })}>
      {children}
    </div>
  </Section>

)

export default PageTitle
