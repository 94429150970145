import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const attendCss = {
  container: () => css({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  }),

  whiteSpace: () => css({
    // NOTE is set to display the footer on the bottom edge of the page
    flex: 1,
  }),

  gridForm: (params: { padding?: number } = {}) => css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
    padding: params.padding ?? 20,

    [appSettings.mediaQueries.desktop]: {
      gridTemplateColumns: "1fr",
    },
  }),

  grid: () => css({
    display: "flex",
    justifyContent: "center",
    gap: 20,
    padding: 20,
    flexWrap: "wrap",
  }),

  corfirmedRoom: () => css({
    display: "flex",
    justifyContent: "center",
    gap: 8,
    lineHeight: "25px",

    [appSettings.mediaQueries.mobile]: {
      justifyContent: "flex-start",
      flexDirection: "column",
      paddingLeft: 20,
      gap: 0,
    },
  }),

  itemName: () => css({
    fontWeight: "bold",
    color: appSettings.colors.blueColor,
  }),

  inputWrapper: (inputParams: { gridColumn?: string, overflow?: string } = {}) => css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    ...inputParams,

    [appSettings.mediaQueries.smallTablet]: {
      gridColumn: "1",
    },
  }),

  label: () => css({
    fontSize: 18,
  }),

  input: ({ isValid }: { isValid: Boolean }) => css({
    fontSize: 16,
    border: `1px solid ${isValid ? appSettings.colors.blueColor : appSettings.colors.redColor}`,
    borderRadius: 8,
    padding: 8,
    outline: "none",

    "&:focus": {
      boxShadow: `0 0 5px ${appSettings.colors.blueColor}`,
    }
  }),

  button: () => css({
    fontSize: 16,
    backgroundColor: appSettings.colors.greenBackground,
    border: `1px solid ${appSettings.colors.blueColor}`,
    borderRadius: 8,
    padding: 8,
    outline: "none",

    "&:active": {
      filter: "brightness(0.9)",
    },
    "&:visited": {
      color: "black",
    }
  }),

  nextRoomButtonWrapper: () => css({
    display: "flex",
    justifyContent: "start",
  }),

  buttonWrapper: () => css({
    display: "flex",
    justifyContent: "center",
  }),

  infoForAccommodation: () => css({
    display: "inline",
    fontSize: 16,
  }),

  info: () => css({
    backgroundColor: appSettings.colors.blueBackground,
    padding: 20,
    textAlign: "center",

    [appSettings.mediaQueries.largeDesktop]: {
      marginInline: 140,
    },

    [appSettings.mediaQueries.desktop]: {
      marginInline: 100,
    },

    [appSettings.mediaQueries.largeTablet]: {
      marginInline: 70,
    },

    [appSettings.mediaQueries.smallTablet]: {
      marginInline: 55,
    },

    [appSettings.mediaQueries.mobile]: {
      marginInline: 45,
    },
  }),
}

export default attendCss
