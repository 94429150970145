import React, { useContext, useEffect, useState } from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'
import { DataContext } from '../../app/data-context'
import { getAll } from '../../service/service'
import allGuestsCss from './all-guests-css'
import { IDBSnapshot, ROOMING_MAP } from '../accommodation/rooming-list'

const AllGuestsView: React.FC = () => {
  const { context } = useContext(DataContext);
  const [data, setData] = useState<IDBSnapshot | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAll();
        setData(result);
      } catch (error) {
        // error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    context?.group?.code === "TOMAR"
      ?
      <>
        <NavBar />
        <PageTitle marginTop>Svatební pozvánky - info</PageTitle>

        {loading
          ?
          <p>Loading ...</p>
          :
          <Section>
            <table className={allGuestsCss.table()}>
              <thead>
                <tr>
                  <th className={allGuestsCss.headerRow()}>Účast potvrzena</th>
                  <th className={allGuestsCss.headerRow()}>Kód pozvánky</th>
                  <th className={allGuestsCss.headerRow()}>Jméno hosta</th>
                  <th className={allGuestsCss.headerRow()}>Email</th>
                  <th className={allGuestsCss.headerRow()}>Zúčastní se</th>
                  <th className={allGuestsCss.headerRow()}>Přes noc</th>
                  <th className={allGuestsCss.headerRow()}>Rezervovaný pokoj</th>
                  <th className={allGuestsCss.headerRow()}>Alergie</th>
                  <th className={allGuestsCss.headerRow()}>Zpráva</th>
                </tr>
              </thead>

              <tbody>
                {data?.groups.map((invitation, index: number) => {
                  console.log({ invitation, index })
                  let isOdd = (index + 1) % 2 !== 0

                  let rows = invitation.visitors.map((guest, i: number) => {
                    let rooms = invitation.accommodation[0] ? ROOMING_MAP[invitation.accommodation[0]] : ""
                    if (invitation.accommodation[1]) rooms = rooms + ", " + ROOMING_MAP[invitation.accommodation[1]]

                    return (
                      <tr key={"invation_" + (index + 1) + "_guest_" + (i + 1)}>
                        <td className={allGuestsCss.row(isOdd, "center")}>{invitation.confirmed ? "✔️" : "❌"}</td>
                        <td className={allGuestsCss.row(isOdd, "center")}>{invitation.code}</td>
                        <td className={allGuestsCss.row(isOdd)}>{(guest.firstName ?? "") + " " + (guest.lastName ?? "")}</td>
                        <td className={allGuestsCss.row(isOdd)}>{guest.email}</td>
                        <td className={allGuestsCss.row(isOdd)}>{guest.attend}</td>
                        <td className={allGuestsCss.row(isOdd, "center")}>{guest.overNight === "true" ? "✔️" : "❌"}</td>
                        <td className={allGuestsCss.row(isOdd)}>{rooms}</td>
                        <td className={allGuestsCss.row(isOdd)}>{guest.allergy}</td>
                        <td className={allGuestsCss.row(isOdd)}>{invitation.notes}</td>
                      </tr>
                    )
                  })

                  return rows
                })
                }
              </tbody>
            </table>
          </Section>}
        <Footer />
      </>
      :
      null
  )
}

export default AllGuestsView
