import React, { ReactNode } from 'react'
import sectionCss from './section-css'

type Props = {
  children: ReactNode,
  isBackgroundBlue?: boolean,
}

const Section: React.FC<Props> = ({ children, isBackgroundBlue }) => (
  <div className={sectionCss.wrapper({ isBackgroundBlue })}>
    {children}
  </div>
)

export default Section
