import React, { useContext, useState } from 'react'
import attendCss from './attend-css'
import Guest from './guest'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import { BED_COUNT_MAP, IGroupData, IRoom, IVisitor, PRICE_MAP, RoomId, ROOMING_MAP } from '../accommodation/rooming-list'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'
import { DataContext } from '../../app/data-context'
import { submitForm } from '../../service/service'
import HtmlLink from '../../components/html-link/html-link'
import ConfirmedGuest from './confirmed-guest'
import confirmedGuestCss from './confirmed-guest-css'
import { Link } from 'react-router-dom'

export type IInvalidInputs = {
  invalidInputs: string[],
}

type IValidation = {
  accommodation: Boolean,
  visitors: IInvalidInputs[],
}

enum FormDataKey {
  ACCOMMODATION1 = 'accommodation1',
  ACCOMMODATION2 = 'accommodation2',
  NOTES = 'notes'
}

type IFormData = {
  [FormDataKey.ACCOMMODATION1]: RoomId | null
  [FormDataKey.ACCOMMODATION2]: RoomId | null
  [FormDataKey.NOTES]: IGroupData["notes"]
}

const AttendView: React.FC = () => {
  const { context, setContext } = useContext(DataContext);
  const [nextRoom, setNextRoom] = useState<boolean>(false);
  const [visitorsData, setVisitorsData] = useState<IVisitor[]>(context?.group?.visitors || [])
  const MORE_GUESTS = context?.group ? context.group?.visitors?.length > 2 : false;

  const [formData, setDataForm] = useState<IFormData>({
    [FormDataKey.ACCOMMODATION1]: context?.group?.accommodation?.[0] || null,
    [FormDataKey.ACCOMMODATION2]: context?.group?.accommodation?.[1] || null,
    [FormDataKey.NOTES]: context?.group?.notes || null,
  })

  const initValidation: IValidation = {
    accommodation: true,
    visitors: []
  }

  const [validation, setValidation] = useState<IValidation>(initValidation);

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, formDataKey: FormDataKey) {
    if (formDataKey === FormDataKey.ACCOMMODATION1 || formDataKey === FormDataKey.ACCOMMODATION2) {
      setValidation((old) => ({ ...old, accommodation: true }))
    }

    setDataForm((prevData) => ({ ...prevData, [formDataKey]: e.target.value }))
  }

  async function handleSubmit() {
    if (context?.group) {
      const accommodation: RoomId[] = [formData[FormDataKey.ACCOMMODATION1], formData[FormDataKey.ACCOMMODATION2]].filter(Boolean).reduce((acc: RoomId[], curr) => curr ? [...acc, curr] : acc, [])

      const updatedGroup: IGroupData = {
        code: context.group.code,
        isVisiblePrice: context.group.isVisiblePrice,
        accommodation,
        confirmed: true,
        notes: formData[FormDataKey.NOTES] || null,
        visitors: visitorsData,
      }

      const validationWithVisitors: IValidation = { ...validation, visitors: visitorsData.map((_) => ({ invalidInputs: [] })) }

      let isValidData = true

      visitorsData.map((visitor, index) => {
        if (!visitor.firstName) {
          validationWithVisitors.visitors[index].invalidInputs.push("firstName")
          isValidData = false
          setValidation(validationWithVisitors)
        }

        if (!visitor.lastName) {
          validationWithVisitors.visitors[index].invalidInputs.push("lastName")
          isValidData = false
          setValidation(validationWithVisitors)
        }

        if (!visitor.attend) {
          validationWithVisitors.visitors[index].invalidInputs.push("attend")
          isValidData = false
          setValidation(validationWithVisitors)
        }

        if (visitor.overNight !== "false" && accommodation.length === 0) {
          isValidData = false
          setValidation({ ...validationWithVisitors, accommodation: false })
        }
      })

      if (isValidData) {
        await submitForm(updatedGroup)
        setContext({ ...context, group: updatedGroup })
      }

    }
  }

  let roomList: IRoom[] = []
  if (context?.rooms && context.rooms.length > 0) {
    roomList = context?.rooms.filter((room) => room.isAvailable)
  }

  return (
    <div className={attendCss.container()}>
      <NavBar />
      <PageTitle marginTop>Potvrzení účasti</PageTitle>

      {/* CONFIRMED GUEST */}
      {context?.group?.confirmed ?
        <>
          <div className={attendCss.info()}>
            Vaše účast byla potvrzena. Těšíme se na vás :)
            <br />
            Doufám, že se také těšíte, dobře se najíte a náš svatební den si pořádně užijete!
          </div>
          <Section>
            <div className={attendCss.grid()}>
              {context?.group?.visitors.map((visitor, i: number) =>
                <ConfirmedGuest key={i + "_confirmed_guest"} guestNumber={i + 1} visitor={visitor} />
              )}
            </div>
            {context.group.accommodation[0] &&
              <div className={attendCss.grid()}>
                <div className={confirmedGuestCss.guestWrapper()}>
                  <div className={confirmedGuestCss.guestItem()}>
                    <span className={confirmedGuestCss.itemTitle()}>Rezervovaný pokoj:</span>
                    <span className={confirmedGuestCss.itemName()}>{ROOMING_MAP[context.group.accommodation[0]]}</span>
                  </div>
                  <div className={confirmedGuestCss.guestItem()}>
                    <span className={confirmedGuestCss.itemTitle()}>Info o pokoji:</span>
                    <span className={confirmedGuestCss.itemName()}>{BED_COUNT_MAP[context.group.accommodation[0]]}</span>
                  </div>
                  {context?.group?.isVisiblePrice &&
                    <div className={confirmedGuestCss.guestItem()}>
                      <span className={confirmedGuestCss.itemTitle()}>Cena:</span>
                      <span className={confirmedGuestCss.itemName()}>{PRICE_MAP[context.group.accommodation[0]]} Kč</span>
                    </div>}
                </div>
              </div>
            }
            {context.group.accommodation[1] &&
              <div className={attendCss.grid()}>
                <div className={confirmedGuestCss.guestWrapper()}>
                  <div className={confirmedGuestCss.guestItem()}>
                    <span className={confirmedGuestCss.itemTitle()}>Rezervovaný pokoj:</span>
                    <span className={confirmedGuestCss.itemName()}>{ROOMING_MAP[context.group.accommodation[1]]}</span>
                  </div>
                  <div className={confirmedGuestCss.guestItem()}>
                    <span className={confirmedGuestCss.itemTitle()}>Info o pokoji:</span>
                    <span className={confirmedGuestCss.itemName()}>{BED_COUNT_MAP[context.group.accommodation[1]]}</span>
                  </div>
                  {context?.group?.isVisiblePrice &&
                    <div className={confirmedGuestCss.guestItem()}>
                      <span className={confirmedGuestCss.itemTitle()}>Cena:</span>
                      <span className={confirmedGuestCss.itemName()}>{PRICE_MAP[context.group.accommodation[1]]}</span>
                    </div>}
                </div>
              </div>
            }
          </Section>
        </>
        :
        <>
          {/* NOT CONFIRMED GUEST */}
          <div className={attendCss.info()} >
            Prosím potvrďte svou účast, abychom věděli kolik jídla, pití a zábavy máme připravit.
            <br />
            Účast můžete potvrdit do konce března.
          </div>
          <Section>
            <div className={attendCss.gridForm()}>
              {context?.group?.visitors.map((visitor, i: number) =>
                <Guest key={i + "_guest"} guestNumber={i + 1} visitor={visitor} setVisitorsData={setVisitorsData} invalidInputs={validation.visitors[i]?.invalidInputs || []} />
              )}
            </div>

            <div className={attendCss.gridForm({ padding: 40 })}>
              <div className={attendCss.inputWrapper({ gridColumn: "1 / 3", overflow: "hidden" })}>
                <label className={attendCss.label()} htmlFor={FormDataKey.ACCOMMODATION1}>Ubytování{" "}
                  <span className={attendCss.infoForAccommodation()}>
                    (Další informace o ubytování včetně fotografií najdete na stránce
                    <HtmlLink href="https://www.vigvamresort.cz/ubytovani/">Resort Vigvam</HtmlLink>
                    )
                  </span>
                </label>
                <select
                  className={attendCss.input({ isValid: validation.accommodation })}
                  id={FormDataKey.ACCOMMODATION1}
                  value={formData[FormDataKey.ACCOMMODATION1] || undefined}
                  onChange={(e) => handleChange(e, FormDataKey.ACCOMMODATION1)}
                >
                  <option value="">-- Vyberte možnost --</option>
                  {roomList.map((room) => {
                    const price = context?.group?.isVisiblePrice ? ", " + room.price + " Kč" : ""

                    return (
                      <option key={room.id} value={room.id}>
                        {room.name} ({room.description}{price})
                      </option>
                    )
                  })}
                </select>
              </div>

              {MORE_GUESTS ? (nextRoom ? <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
                <label className={attendCss.label()} htmlFor={FormDataKey.ACCOMMODATION2}>Další pokoj</label>
                <select
                  className={attendCss.input({ isValid: validation.accommodation })}
                  id={FormDataKey.ACCOMMODATION2}
                  value={formData[FormDataKey.ACCOMMODATION2] || undefined}
                  onChange={(e) => handleChange(e, FormDataKey.ACCOMMODATION2)}
                >
                  <option value="">-- Vyberte možnost --</option>
                  {roomList.map((room) => {
                    const price = context?.group?.isVisiblePrice ? ", " + room.price + " Kč" : ""
                    return (
                      <option key={room.id} value={room.id}>
                        {room.name}  ({room.description}{price})
                      </option>
                    )
                  })}
                </select>
              </div> : <div className={attendCss.nextRoomButtonWrapper()}><button className={attendCss.button()} onClick={() => setNextRoom(true)}>Přidat další pokoj</button></div>) : null}

              <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
                <label className={attendCss.label()} htmlFor={FormDataKey.NOTES}>Otázky nebo nějaká zpráva pro novomanžele :)</label>
                <textarea
                  className={attendCss.input({ isValid: true })}
                  id={FormDataKey.NOTES}
                  rows={10}
                  value={formData[FormDataKey.NOTES] || ""}
                  onChange={(e) => handleChange(e, FormDataKey.NOTES)}
                />
              </div>
            </div>

            <div className={attendCss.buttonWrapper()}>
              <button className={attendCss.button()} onClick={handleSubmit} disabled={context?.group?.code?.toUpperCase() === "KLARA"}>Potvrdit účast</button>
            </div>
          </Section>
        </>
      }
      <div className={attendCss.whiteSpace()}></div>
      {context?.group?.code?.toUpperCase() === "TOMAR" ? <div className={attendCss.buttonWrapper()}><Link to="/all-guests" className={attendCss.button()}>Účast hostů</Link></div> : null}
      <Footer />
    </div>
  )
}

export default AttendView
