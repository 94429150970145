import React, { useContext } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import HomeView from './home-view'

const Home: React.FC = () => {
  const { context } = useContext(DataContext);

  return !context?.group ? <Login /> : <HomeView />
}

export default Home
