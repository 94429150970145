import React from 'react'
import HomeHeader from './home-header'
import HomeBody from './home-body'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'

const HomeView: React.FC = () => {
  return (
    <>
      <NavBar />
      <HomeHeader />
      <HomeBody />
      <Footer />
    </>
  )
}

export default HomeView
