import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const loginCss = {
  container: () => css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),

  wrapper: () => css({
    display: "flex",
    flexDirection: "column",
    width: 250,
    gap: 8,
  }),

  input: () => css({
    fontSize: 16,
    border: `1px solid ${appSettings.colors.blueColor}`,
    borderRadius: 8,
    padding: 8,
    outline: "none",

    "&:focus": {
      boxShadow: `0 0 5px ${appSettings.colors.blueColor}`,
    }
  }),

  button: () => css({
    fontSize: 16,
    backgroundColor: appSettings.colors.greenBackground,
    border: `1px solid ${appSettings.colors.blueColor}`,
    borderRadius: 8,
    padding: 8,
    outline: "none",

    "&:active": {
      filter: "brightness(0.9)",
    }
  }),
}

export default loginCss
