import React from 'react'
import cateringCss from './catering-css'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'
import EmptyContent from '../../components/empty-content/empty-content'

const CateringView: React.FC = () => {
  return (
    <>
      <NavBar />
      <PageTitle marginTop>Na jaké dobroty se můžete těšit?</PageTitle>

      {/* starter */}
      <Section isBackgroundBlue>
        <div className={cateringCss.section()}>
          <p className={cateringCss.sectionTitle()}>Lehké občerstvení před obřadem</p>
          <p className={cateringCss.sectionSubtitle()}>Občerstvení najdete v restauraci před vigvamem.</p>

          {/* 1. TEMPORARY (remove)*/}
          <Section>
            <EmptyContent>Seznam pokrmů doplníme, jakmile budeme mít konečné rozhodnutí.</EmptyContent>
          </Section>

          {/* 2. CONTENT 
        <ul>
          <li>Pěna z modrého sýra s vlašským ořechem</li>
          <li>Šunková pěna s okurkou a rajčaty</li>
          <li>Klobása s cibulkou cipollini a okurkou</li>
          <li>Cherry rajčátko plněné sýrem ricotta s pažitkou</li>
          <li>Parmská šunka s melounem a rucolou</li>
          <li>Párečky od pana Černého</li>
          <li>Variace pomazánek na domácím chlebu</li>
          <li>Ovocné špízky</li>
        </ul>*/}
        </div>
      </Section>

      {/* lunch */}
      <Section>
        <div className={cateringCss.section()}>
          <p className={cateringCss.sectionTitle()}>Svatební menu</p>
          <p className={cateringCss.sectionSubtitle()}>Oběd se bude podávat uvnitř vigvamu.</p>

          {/* 1. TEMPORARY (remove)*/}
          <Section>
            <EmptyContent>Seznam pokrmů doplníme, jakmile budeme mít konečné rozhodnutí.</EmptyContent>
          </Section>

          {/* 2. CONTENT 
        <p className={cateringCss.lunchSubtitle()}>Předkrm</p>
        <p>Pražská šunka s křenovým krémem, listovým salátkem a jablkem pečeným v medu</p>

        <p className={cateringCss.lunchSubtitle()}>Polévka</p>
        <p>Hovězí vývar s játrovými knedlíčky a zeleninou</p>

        <p className={cateringCss.lunchSubtitle()}>Hlavní chod</p>
        <p>Hovězí pečeně na smetaně s karlovarským knedlíkem a brusinkami</p>

        <p className={cateringCss.lunchSubtitle()}>Dezert</p>
        <p>Svatební dort</p>*/}
        </div>
      </Section>

      {/* seating plan */}
      <Section isBackgroundBlue>
        <div className={cateringCss.section()}>
          <p className={cateringCss.sectionTitle()}>Zasedací pořádek na oběd uvnitř vigvamu</p>
          <p className={cateringCss.sectionSubtitle()}>Pro hladší průběh doporučujeme si prohlédnout zasedací pořádek, abyste věděli, kde budete sedět.</p>

          {/* 1. TEMPORARY (remove) */}
          <Section>
            <EmptyContent>Zasedací pořádek doplníme, jakmile budeme mít potvrzenou účast od všech hostů.</EmptyContent>
          </Section>

          {/* 2. CONTENT 
        <img src="assets/seating-plan.jpg" alt="foto-1" className={cateringCss.seatingPlanFoto()} />*/}
        </div>
      </Section>

      {/* buffet */}
      <Section>
        <div className={cateringCss.section()}>
          <p className={cateringCss.sectionTitle()}>Večerní raut včetně grilování</p>
          <p className={cateringCss.sectionSubtitle()}>Raut bude k dispozici v podvečer. Šéfkuchaři budou grilovat venku za vigvamem.</p>

          {/* 1. TEMPORARY (remove)*/}
          <Section>
            <EmptyContent>Seznam pokrmů doplníme, jakmile budeme mít konečné rozhodnutí.</EmptyContent>
          </Section>

          {/* 2. CONTENT
        <ul>
          <li>Roastbeef s wasabi majonézou</li>
          <li>Lehce zauzená kachní prsa s chutney</li>
          <li>Bruschetta</li>
          <li>Vepřové mini řízečky</li>
          <li>Kuřecí mini řízečky</li>
          <li>Filírované krůtí prso s omáčkou z pečených paprik</li>
          <li>Hovězí tataráček s topinkami</li>
          <li>Uzenářské prkénko zdobené beraními rohy</li>
          <li>Sýrové prkénko zdobené ovocem</li>
          <li>Marinovaný losos s koprovým dresinkem</li>
          <li>Maki sushi</li>
          <li>Mozzarella caprese salát</li>
          <li>Salát Coleslaw</li>
          <li>Čerstvá zelenina</li>
          <li>Černé olivy</li>
          <li>Balkánský sýr</li>
          <li>Grilovaná filírovaná masa: hovězí, zvěřina, vepřové, kuřecí</li>
          <li>Grilovaný steak z lososa marinovaný v citrusech</li>
          <li>Slávky – rajčata, česnek, víno, bylinky</li>
          <li>Grilované krevety, citrusy, bylinky</li>
          <li>Domácí mini hamburgery</li>
          <li>Grilovaný hermelín</li>
          <li>Dresinky a salsa</li>
          <li>Grilovaná naše zelenina</li>
          <li>Rozmarýnové grenaille</li>
          <li>Dresinky, pečivový set</li>
        </ul>*/}
        </div>
      </Section>

      {/* sweet bar */}
      <Section isBackgroundBlue>
        <div className={cateringCss.section()}>
          <p className={cateringCss.sectionTitle()}>Sweet bar</p>
          <p className={cateringCss.sectionSubtitle()}>Něco sladkého najdete uvnitř vigvamu.</p>

          {/* 1. TEMPORARY (remove)*/}
          <Section>
            <EmptyContent>Seznam pokrmů doplníme, jakmile budeme mít konečné rozhodnutí.</EmptyContent>
          </Section>

          {/* 2. CONTENT
        <ul>
          <li>Makronky (mix chutí)</li>
          <li>Cupcakes</li>
          <li>Panna cotta</li>
          <li>Ovocné tartaletky</li>
          <li>Mini Pavlova</li>
          <li>Věnečky</li>
        </ul>*/}
        </div>
      </Section>
      <Footer />
    </>
  )
}

export default CateringView
