import React, { useContext, useRef } from 'react'
import loginCss from './login-css'
import PageTitle from '../page-title/page-title'
import { DataContext } from '../../app/data-context';
import { getDataForForm } from '../../service/service';

const Login: React.FC = () => {
  const { setContext: setData } = useContext(DataContext);
  const inputRef = useRef<HTMLInputElement>(null);

  async function handleClick() {
    if (inputRef.current) {
      const data = await getDataForForm(inputRef.current.value);
      setData(data);
    }
  }

  function handleKeyDown(e: { key: string }) {
    if (e.key === "Enter") handleClick()
  }

  return (
    <div className={loginCss.container()}>
      <PageTitle marginTop>Zadejte kód pozvánky</PageTitle>
      <div className={loginCss.wrapper()}>
        <input ref={inputRef} className={loginCss.input()} type="text" id="code" required onKeyDown={handleKeyDown} />
        <button className={loginCss.button()} onClick={handleClick}>Potvrdit</button>
      </div>
    </div>
  )
}

export default Login
