import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const htmlLinkCss = {
  wrapper: () => css({
    color: appSettings.colors.blueColor,
    marginLeft: 4,
    
    "&:visited": {
      color: appSettings.colors.blueColor,
    }
  }),
}

export default htmlLinkCss
