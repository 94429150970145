import { createContext, FC, ReactNode, useState } from "react";
import { IContextData } from "../pages/accommodation/rooming-list";

type DataContextType = {
  context: IContextData | null;
  setContext: (contextToSave: IContextData) => void;
}

export const DataContext = createContext<DataContextType>({
  context: null,
  setContext: () => {},
});

type DataProviderProps = {
  children: ReactNode;
}

export const DataProvider: FC<DataProviderProps> = ({ children }) => {
  const [context, setState] = useState<IContextData | null>(null);
  const setContext = (contextToSave: IContextData) => setState(contextToSave);

  return (
    <DataContext.Provider value={{ context, setContext }}>
      {children}
    </DataContext.Provider>
  );
};