import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Global } from '@emotion/react';
import appGlobalCss from './app-global-css';
import { DataProvider } from './data-context';

// pages
import AllGuests from '../pages/all-guests/all-guests';
import Attend from '../pages/attend/attend';
import Gifts from '../pages/gifts/gifts';
import Photo from '../pages/photo/photo';
import Program from '../pages/program/program';
import Catering from '../pages/catering/catering';
import Accommodation from '../pages/accommodation/accommodation';
import Home from '../pages/home/home';

const App: React.FC = () => (
  <DataProvider>
    <Global styles={appGlobalCss} />
    <Routes>
      <Route path="/all-guests" element={<AllGuests />}></Route>
      <Route path="/attend" element={<Attend />}></Route>
      <Route path="/gifts" element={<Gifts />}></Route>
      <Route path="/photo" element={<Photo />}></Route>
      <Route path="/program" element={<Program />}></Route>
      <Route path="/catering" element={<Catering />}></Route>
      <Route path="/accommodation" element={<Accommodation />}></Route>
      <Route path="/" element={<Home />}></Route>
    </Routes>
  </DataProvider>
)

export default App;
