import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const homeBodyCss = {
  marginTop: () => css({
    paddingTop: 40,
    background: appSettings.colors.blueBackground,

    [appSettings.mediaQueries.mobile]: {
      paddingTop: 70, // due to countdown timer
    }
  }),

  medallionWrapper: () => css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [appSettings.mediaQueries.largeDesktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.desktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.largeTablet]: {
      gap: 40,
    },

    [appSettings.mediaQueries.smallTablet]: {
      flexDirection: "column",
      gap: 30,
    },

    [appSettings.mediaQueries.mobile]: {
      gap: 20,
    },
  }),

  medallion: ({ textAlign, marginTop }: { textAlign: "left" | "right", marginTop?: number }) => css({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    textAlign,

    [appSettings.mediaQueries.smallTablet]: {
      order: 1,
      textAlign: "center",
      marginTop,
    },
  }),

  medallionTitle: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 22,
    color: appSettings.colors.blueColor,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 25,
    },

    [appSettings.mediaQueries.smallTablet]: {
      fontSize: 20,
    },
  }),

  foto: () => css({
    width: 200,
    height: 200,
    borderRadius: "50%",

    [appSettings.mediaQueries.smallTablet]: {
      order: 2,
      textAlign: "center",
    },
  }),

  vigvamWrapper: () => css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",

    [appSettings.mediaQueries.largeDesktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.desktop]: {
      gap: 60,
    },

    [appSettings.mediaQueries.largeTablet]: {
      gap: 40,
    },

    [appSettings.mediaQueries.smallTablet]: {
      gridTemplateColumns: "1fr",
      gap: 30,
    },

    [appSettings.mediaQueries.mobile]: {
      gap: 20,
    },
  }),

  vigvamTitle: () => css({
    fontFamily: "CinzelRegular",
    fontSize: 22,
    textAlign: "center",
    color: appSettings.colors.blueColor,
    marginBottom: 20,

    [appSettings.mediaQueries.largeDesktop]: {
      fontSize: 25,
    },

    [appSettings.mediaQueries.smallTablet]: {
      flexDirection: "column",
      fontSize: 20,
    },
  }),

  vigvam: () => css({
    display: "flex",
    flexDirection: "column",
  }),

  vigvamFoto: () => css({
    width: "100%",
    height: "100%",
    objectFit: "cover",

    [appSettings.mediaQueries.smallTablet]: {
      marginTop: -20
    },
  }),

  colors: () => css({
    // TODO
    display: "flex",
    flexDirection: "column",
    height: 300,
    gap: 20,
  }),
}

export default homeBodyCss
