import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const guestCss = {
  guest: () => css({
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "CinzelRegular",
    color: appSettings.colors.blueColor,
    fontSize: 18,
    marginBottom: 8,
    lineHeight: "26px",
  }),

  gridForm: () => css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 20,
    padding: 20,
    backgroundColor: appSettings.colors.blueBackground,
    position: "relative",

    [appSettings.mediaQueries.smallTablet]: {
      gridTemplateColumns: "1fr",
    },
  }),

  radioList: () => css({
    display: "flex",
    height: "100%",
    alignItems: "center",
    gap: 20,
  }),

  cancelGuest: () => css({
    position: "absolute",
    top: 5,
    right: 5,
    borderRadius: 8,
    padding: 4,
  }),

  buttonWrapper: () => css({
    paddingTop: 34,

    [appSettings.mediaQueries.desktop]: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 0,
    },
  }),
}

export default guestCss
