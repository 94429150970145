import React from 'react'
import accommodationCss from './accommodation-css'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import Section from '../../components/section/section'
import HtmlLink from '../../components/html-link/html-link'
import PageTitle from '../../components/page-title/page-title'

const AccommodationView: React.FC = () => {
  const MapURL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1531.797444387579!2d15.296694830713387!3d50.082194353776956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c3e078456dee7%3A0xe43ada998f9c585b!2zTsSbbcSNaWNlIDE1MiwgMjgwIDAyIE7Em23EjWljZSwgxIxlc2tv!5e1!3m2!1scs!2sus!4v1734637249315!5m2!1scs!2sus"

  return (
    <>
      <NavBar />

      {/* header */}
      <div className={accommodationCss.headerWrapper()}>
        <img src="assets/vigvam_1.jpg" alt="vigvam" className={accommodationCss.vigvamFoto()} />
        <p className={accommodationCss.header()}>Resort Vigvam</p>
      </div>

      {/* info */}
      <Section isBackgroundBlue>
        Naše svatba se bude konat v nádherném resortu Vigvam, který se nachází v malebném prostředí ve Středočeském kraji.
        Toto jedinečné místo nabízí nejen krásnou přírodu, ale i stylové prostory, ideální pro svatební den plný nezapomenutelných okamžiků.
        Obřad proběhne ve venkovním prostředí resortu, kde si řekneme své „ano“ pod širým nebem.
        Po obřadu nás čeká svatební oběd, který se uskuteční v originálním Vigvamu.
        Šéfkuchař resortu připraví menu plné lahodných pokrmů, které potěší jak na pohled, tak i svou chutí.
        <HtmlLink href="https://www.vigvamresort.cz/">Resort Vigvam</HtmlLink> nabízí vše, co potřebujeme pro náš velký den.
        Doufáme, že si zde užijeme nejen my, ale i naši hosté.
        Těšíme se na společně strávené chvíle na tomto výjimečném místě!
      </Section>

      {/* accommodation */}
      <PageTitle >Ubytování</PageTitle>
      <Section>
        Pro naše hosty nabízí Resort Vigvam pohodlné a stylové ubytování přímo na místě, díky kterému si můžete svatební den užít naplno, bez starostí o cestování.
        Po dni plném oslav si navíc budete mít kde složit hlavu.
        Resort disponuje různými typy pokojů od originálních maringotek a dřevěných sudů až po klasické hotelové pokoje.
        Všechny jsou zařízené ve venkovském stylu, který vytváří příjemnou a útulnou atmosféru.
        Každý pokoj je vybaven moderním zázemím, včetně vlastní koupelny, Wi-Fi připojení a pohodlné postele.
        <br />
        <br />
        Během potvrzení účasti si můžete vybrat některý z pokojů nabízených v Resortu Vigvam, který pro vás v den svatby zarezervujeme.
        Náklady na ubytování si hosté hradí sami, přičemž platba proběhne následující den po svatbě (20. 7. 2025) na recepci.
      </Section>

      {/* parking */}
      <PageTitle isBackgroundBlue>Parkování</PageTitle>
      <Section isBackgroundBlue>
        Parkování je zajištěno přímo v areálu resortu Vigvam.
        Parkování je zdarma a k dispozici je dostatek míst pro všechny hosty.
        Po průjezdu branou najdete parkoviště přímo před sebou, na pravé straně.
      </Section>

      {/* map */}
      <div id="map" className={accommodationCss.map()}>
        <a className={accommodationCss.button()} rel="noreferrer" href="https://www.google.com/maps/dir/?api=1&destination=50.081725650091805,15.296592624304955" target="_blank" >
          Spustit navigaci
        </a>
        <iframe
          title="map"
          src={MapURL}
          className={accommodationCss.iframe()}
          height="300"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      <Footer />
    </>
  )
}

export default AccommodationView
