import React, { useEffect, useState } from 'react'
import attendCss from './attend-css'
import guestCss from './guest-css'
import { IVisitor } from '../accommodation/rooming-list'

type Props = {
  visitor: IVisitor
  guestNumber: number,
  invalidInputs: string[],
  setVisitorsData: (value: React.SetStateAction<IVisitor[]>) => void,
}

const Guest: React.FC<Props> = ({ visitor, guestNumber, invalidInputs, setVisitorsData }) => {
  const [displayForm, setDisplayForm] = useState(true)
  const [isFocused, setIsFocused] = useState(true) // true is due to first mount

  enum VisitorKey {
    FIRST_NAME_ID = "firstName",
    LAST_NAME_ID = "lastName",
    EMAIL_ID = "email",
    ATTEND_ID = "attend",
    OVERNIGHT_ID = "overNight",
    ALLERGY_ID = "allergy",
  }

  const [formData, setDataForm] = useState<IVisitor>(visitor)

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, key: VisitorKey) {
    setDataForm((prevData) => ({
      ...prevData,
      [key]: e.target.value,
    }))
  }

  useEffect(() => {
    if (!isFocused) {
      setVisitorsData((visitors) => {
        visitors[guestNumber - 1] = formData

        return visitors
      })
    }
  }, [isFocused])

  return (
    <div>
      {displayForm ?
        <>
          <p className={guestCss.guest()}>{guestNumber}. Host</p>
          <div className={guestCss.gridForm()}>
            {guestNumber > 1 &&
              <button
                onClick={() => {
                  setDisplayForm(false)
                  setVisitorsData((prevData) => {
                    let newVisitors = [...prevData].filter((_, i) => i !== guestNumber - 1 )
                    return newVisitors
                  })
                }}
                className={guestCss.cancelGuest()}
              >
                ❌
              </button>}

            <div className={attendCss.inputWrapper()}>
              <label className={attendCss.label()} htmlFor={VisitorKey.FIRST_NAME_ID}>Jméno *</label>
              <input
                className={attendCss.input({ isValid: !invalidInputs.find((id) => id === VisitorKey.FIRST_NAME_ID) })}
                type="text"
                id={VisitorKey.FIRST_NAME_ID}
                required
                value={formData[VisitorKey.FIRST_NAME_ID] || ""}
                onChange={(e) => handleChange(e, VisitorKey.FIRST_NAME_ID)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>

            <div className={attendCss.inputWrapper()}>
              <label className={attendCss.label()} htmlFor={VisitorKey.LAST_NAME_ID}>Příjmení *</label>
              <input
                className={attendCss.input({ isValid: !invalidInputs.find((id) => id === VisitorKey.LAST_NAME_ID) })}
                type="text"
                id={VisitorKey.LAST_NAME_ID}
                required
                value={formData[VisitorKey.LAST_NAME_ID] || ""}
                onChange={(e) => handleChange(e, VisitorKey.LAST_NAME_ID)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>

            <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
              <label className={attendCss.label()} htmlFor={VisitorKey.EMAIL_ID}>Email</label>
              <input
                className={attendCss.input({ isValid: true })}
                type="email"
                id={VisitorKey.EMAIL_ID}
                value={formData[VisitorKey.EMAIL_ID] || ""}
                onChange={(e) => handleChange(e, VisitorKey.EMAIL_ID)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>

            <div className={attendCss.inputWrapper()}>
              <label className={attendCss.label()} htmlFor={VisitorKey.ATTEND_ID}>Zúčastním se *</label>
              <select
                className={attendCss.input({ isValid: !invalidInputs.find((id) => id === VisitorKey.ATTEND_ID) })}
                id={VisitorKey.ATTEND_ID}
                required
                value={formData[VisitorKey.ATTEND_ID]}
                onChange={(e) => handleChange(e, VisitorKey.ATTEND_ID)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              >
                <option value="">-- Vyberte možnost --</option>
                <option value="Obřadu">Obřadu</option>
                <option value="Obřadu a oběda">Obřadu a oběda</option>
                <option value="Budu od začátku do konce">Budu od začátku do konce</option>
              </select>
            </div>

            <div className={attendCss.inputWrapper()}>
              <label className={attendCss.label()} htmlFor={VisitorKey.OVERNIGHT_ID}>Zůstanu přes noc</label>
              <div className={guestCss.radioList()}>
                <span>
                  <input
                    type="radio"
                    id={VisitorKey.OVERNIGHT_ID}
                    value="true"
                    checked={formData[VisitorKey.OVERNIGHT_ID] === "true"}
                    onChange={(e) => handleChange(e, VisitorKey.OVERNIGHT_ID)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />Ano</span>
                <span>
                  <input
                    type="radio"
                    id={VisitorKey.OVERNIGHT_ID}
                    value="false"
                    checked={formData[VisitorKey.OVERNIGHT_ID] === "false"}
                    onChange={(e) => handleChange(e, VisitorKey.OVERNIGHT_ID)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />Ne</span>
              </div>
            </div>

            <div className={attendCss.inputWrapper({ gridColumn: "1 / 3" })}>
              <label className={attendCss.label()} htmlFor={VisitorKey.ALLERGY_ID}>Alergie na nějaké jídlo?</label>
              <input
                className={attendCss.input({ isValid: true })}
                type="text"
                id={VisitorKey.ALLERGY_ID}
                placeholder='Ořechy, mléko...'
                value={formData[VisitorKey.ALLERGY_ID] || ""}
                onChange={(e) => handleChange(e, VisitorKey.ALLERGY_ID)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>
          </div>
        </>
        :
        <div className={guestCss.buttonWrapper()}>
          <button onClick={() => setDisplayForm(true)} className={attendCss.button()}>Přidat hosta</button>
        </div>
      }

    </div>
  )
}

export default Guest
