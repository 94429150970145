import React from 'react'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import giftsCss from './gifts-css'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'

const GiftsView: React.FC = () => {
  return (
    <div className={giftsCss.container()}>
      <NavBar />

      <PageTitle marginTop>Nevíte si rady s darem pro nás?</PageTitle>

      <Section>
        <div className={giftsCss.wrapper()}>
          <p className={giftsCss.info()}>
            Svatebčané naši milí, nelamte si hlavičky, jaké máte na hostinu pro nás přinést dárečky. Věcí máme plné skříně, v nájmu malý byteček, za korunky o to dříve postavíme domeček. Za každý zlaťáček děkujeme srdečně, hlavně náš šťastný den oslavme společně!
          </p>
        </div>
      </Section>
      <div className={giftsCss.whiteSpace()}></div>
      <Footer />
    </div>
  )
}

export default GiftsView
