import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const confirmedGuestCss = {
  guestWrapper: () => css({
    width: 500,
  }),

  guest: () => css({
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "CinzelRegular",
    color: appSettings.colors.blueColor,
    fontSize: 18,
    marginBottom: 8,
    lineHeight: "26px",
  }),

  guestItem: () => css({
    display: "flex",
    justifyContent: "space-between",
    gap: 8,

    [appSettings.mediaQueries.mobile]: {
      flexDirection: "column",
      gap: 0,
      marginBottom: 8,
    },
  }),

  itemTitle: () => css({
    flex: 1,
    lineHeight: "25px",
    wordBreak: "break-word",
  }),

  itemName: () => css({
    flex: 1,
    lineHeight: "25px",
    wordBreak: "break-word",
    fontWeight: "bold",
    color: appSettings.colors.blueColor,
  }),
}

export default confirmedGuestCss
