import React, { useContext } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import CateringView from './catering-view'

const Catering: React.FC = () => {
  const { context } = useContext(DataContext);

  return !context?.group ? <Login /> : <CateringView />
}

export default Catering
