import React from 'react'
import programCss from './program-css'
import NavBar from '../../components/nav-bar/nav-bar'
import Footer from '../../components/footer/footer'
import PageTitle from '../../components/page-title/page-title'
import Section from '../../components/section/section'

type timelinePoint = {
  name: string;
  time: string;
  description: string;
  icon: string;
  visible: boolean;
};

const TIMELINE_MAP: Record<string, timelinePoint> = {
  "point1": {
    name: "Příjezd hostů",
    time: "10 : 00",
    description: "Parkování je možné v resortu. Prosíme, naplánujte si cestu tak, abyste dorazili ještě před obřadem. Drobné občerstvení bude připraveno v restauraci.",
    icon: "assets/car.png",
    visible: true,
  },
  "point2": {
    name: "Obřad",
    time: "11 : 00",
    description: "Prosíme, při obřadu nevytahujte telefony. Máme úžasného kameramana a fotografku, kteří vše zdokumentují za vás, a byla by škoda, kdybychom na fotkách viděli displeje vašich mobilů.",
    icon: "assets/ceremony.png",
    visible: true,
  },
  "point3": {
    name: "Focení s rodinou",
    time: "12 : 00",
    description: "xxx",
    icon: "assets/question.png",
    // icon: "assets/photo.png",
    visible: false,
  },
  "point4": {
    name: "Oběd",
    time: "13 : 30",
    description: "xxx",
    icon: "assets/question.png",
    // // icon: "assets/lunch.png",
    visible: false,
  },
  "point5": {
    name: "Krájení dortu",
    time: "15 : 00",
    description: "xxx",
    icon: "assets/question.png",
    // icon: "assets/cake.png",
    visible: false,
  },
  "point6": {
    name: "'Házení' kytice",
    time: "16 : 00",
    description: "Házet kyticí se ve skutečnosti nebude, ale budou se stříhat stuhy.",
    icon: "assets/question.png",
    // icon: "assets/flower.png",
    visible: false,
  },
  "point7": {
    name: "Hry",
    time: "17 : 00",
    description: "Volný program, hry s novomanžely",
    icon: "assets/question.png",
    // icon: "assets/game.png",
    visible: false,
  },
  "point8": {
    name: "První tanec",
    time: "18 : 00",
    description: "První tanec novomanželů",
    icon: "assets/question.png",
    // icon: "assets/dance.png",
    visible: false,
  },
  "point9": {
    name: "Volný program",
    time: "19 : 00",
    description: "xxx",
    icon: "assets/question.png",
    // icon: "assets/music.png",
    visible: false,
  },
}

const ProgramView: React.FC = () => {
  return (
    <>
      <NavBar />
      <PageTitle marginTop>Co máme v plánu během dne?</PageTitle>
      <Section>
        <div className={programCss.timeline()}>
          {Object.keys(TIMELINE_MAP).map((it) => {
            return (
              <div key={it} className={programCss.timelineItem()}>
                <div className={programCss.time(TIMELINE_MAP[it].visible)}>{TIMELINE_MAP[it].time}</div>
                <img src={TIMELINE_MAP[it].icon} alt="icon" className={programCss.icon()} />
                <div>
                  <p className={programCss.name(TIMELINE_MAP[it].visible)}>{TIMELINE_MAP[it].name}</p>
                  <p className={programCss.description(TIMELINE_MAP[it].visible)}>{TIMELINE_MAP[it].description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Section>
      <Footer />
    </>
  )
}

export default ProgramView
