import React from 'react'
import homeBodyCss from './home-body-css'
import Section from '../../components/section/section'
import PageTitle from '../../components/page-title/page-title'
import HtmlLink from '../../components/html-link/html-link'

const HomeBody: React.FC = () => (
  <>
    <div className={homeBodyCss.marginTop()}>
      {/* first meeting */}
      <PageTitle isBackgroundBlue>Náš příběh</PageTitle>
    </div>

    <Section isBackgroundBlue>
      <div className={homeBodyCss.medallionWrapper()}>
        <div className={homeBodyCss.medallion({ textAlign: "left", marginTop: -20 })}>
          <p className={homeBodyCss.medallionTitle()}>Jak to začalo...</p>
          <p>
            Poznali jsme se na letní brigádě v roce 2015.
            O dva roky později jsme se na té stejné brigádě znovu setkali a natolik sblížili, že jsme ve volných chvílích vyhledávali jeden druhého.
            První polibek a zároveň náš krásný vztah začal 3. 8. 2017.
            Až láska nám ukázala, co je v životě opravdu důležité.
            Dnes už víme, že štěstí je jen poloviční, když se o něj nemáš s kým podělit, a že smutek je dvojnásobný, když ti z něj nemá kdo pomoci.
            Spolu jsme poznali, co to znamená skutečně milovat a jaké to je, když je láska opětována.
          </p>
        </div>
        <img src="assets/foto_1.jpg" alt="foto-1" className={homeBodyCss.foto()} />
      </div>
    </Section>

    <Section>
      <div className={homeBodyCss.medallionWrapper()}>
        <img src="assets/foto_2.jpg" alt="foto-2" className={homeBodyCss.foto()} />
        <div className={homeBodyCss.medallion({ textAlign: "right" })}>
          <p className={homeBodyCss.medallionTitle()}>Společná cesta a zásnuby</p>
          <p>
            Po vzájemném poznávání a Marti studiu nastal čas sdílet jednu adresu v Praze.
            Náš skromný byt se stal místem, kde jsme se naučili spolu žít, dělit se o radosti i starosti a budovat pevné základy našeho vztahu i společné budoucnosti.
            Navzájem jsme se podporovali ve všech výzvách, které nám život přinesl, ať už šlo o osobní, nebo profesní milníky.
            Byly to roky plné smíchu, společných zážitků a neocenitelných zkušeností, které nás ještě více spojily.
            Po sedmi nádherných letech soužití, během nichž jsme si vytvořili nespočet krásných vzpomínek, se Tom rozhodl udělat další významný krok.
            V podvečer 3. srpna 2024, na romantickém pražském Petříně, při západu slunce a s výhledem na celé město, poklekl a požádal Marťu o ruku.
            Byl to okamžik plný emocí, štěstí a lásky, na který nikdy nezapomeneme.
            Od toho dne jsme začali plánovat další kapitolu našeho společného života a těšíme se na vše, co nám budoucnost přinese.
          </p>
        </div>
      </div>
    </Section>

    {/* vigvam */}
    <PageTitle isBackgroundBlue>Kde se bude konat svatba?</PageTitle>
    <Section isBackgroundBlue>
      <div className={homeBodyCss.vigvamWrapper()}>
        <img src="assets/vigvam_1.jpg" alt="vigvam" className={homeBodyCss.vigvamFoto()} />
        <div className={homeBodyCss.vigvam()}>
          <div className={homeBodyCss.vigvamTitle()}>Resort Vigvam</div>
          <p>
            Naše svatba se bude konat v nádherném resortu Vigvam, který se nachází v malebném prostředí ve Středočeském kraji.
            Toto jedinečné místo nabízí nejen krásnou přírodu, ale i stylové prostory, ideální pro svatební den plný nezapomenutelných okamžiků.
            Obřad proběhne ve venkovním prostředí resortu, kde si řekneme své „ano“ pod širým nebem.
            Po obřadu nás čeká svatební oběd, který se uskuteční v originálním Vigvamu.
            Šéfkuchař resortu připraví menu plné lahodných pokrmů, které potěší jak na pohled, tak i svou chutí.
            <HtmlLink href="https://www.vigvamresort.cz/">Resort Vigvam</HtmlLink> nabízí vše, co potřebujeme pro náš velký den.
            Doufáme, že si zde užijeme nejen my, ale i naši hosté.
            Těšíme se na společně strávené chvíle na tomto výjimečném místě!
          </p>
        </div>
      </div>
    </Section>

    {/* colors
    <PageTitle isBackgroundBlue>Co na sebe?</PageTitle>
    <Section isBackgroundBlue>
      <div className={homeBodyCss.colors()}>
        <p>Vše v náš velký den bude laděno do bílé a světle modré barvy.</p>
        <p>Udělá nám radost pokud se s námi také sladíte a do svého outfitu přidáte trochu modré jakýmkoliv způsobem, který vám bude příjemný.</p>
      </div>
    </Section> */}
  </>
)

export default HomeBody
