import React from 'react'
import { IVisitor } from '../accommodation/rooming-list'
import confirmedGuestCss from './confirmed-guest-css'

type Props = {
  visitor: IVisitor
  guestNumber: number,
}

const ConfirmedGuest: React.FC<Props> = ({ visitor, guestNumber }) => {

  return (
    <div className={confirmedGuestCss.guestWrapper()}>
      <p className={confirmedGuestCss.guest()}>{guestNumber}. Host</p>
      <div className={confirmedGuestCss.guestItem()}>
        <span className={confirmedGuestCss.itemTitle()}>Jméno:</span>
        <span className={confirmedGuestCss.itemName()}>{visitor.firstName + " " + visitor.lastName}</span>
      </div>
      {visitor.email && <div className={confirmedGuestCss.guestItem()}>
        <span className={confirmedGuestCss.itemTitle()}>Email:</span>
        <span className={confirmedGuestCss.itemName()}>{visitor.email}</span>
      </div>}
      <div className={confirmedGuestCss.guestItem()}>
        <span className={confirmedGuestCss.itemTitle()}>Zúčastní se:</span>
        <span className={confirmedGuestCss.itemName()}>{visitor.attend}</span>
      </div>
      <div className={confirmedGuestCss.guestItem()}>
        <span className={confirmedGuestCss.itemTitle()}>Zůstane přes noc:</span>
        <span className={confirmedGuestCss.itemName()}>{visitor.overNight === "true" ? "Ano" : "Ne"}</span>
      </div>
      {visitor.allergy && <div className={confirmedGuestCss.guestItem()}>
        <span className={confirmedGuestCss.itemTitle()}>Alergie:</span>
        <span className={confirmedGuestCss.itemName()}>{visitor.allergy}</span>
      </div>}
    </div>
  )
}

export default ConfirmedGuest
