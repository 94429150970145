import React, { useContext, useEffect } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import AttendView from './attend-view'
import { getDataForForm } from '../../service/service'
import { useSearchParams } from 'react-router-dom'

const Attend: React.FC = () => {
  const { context, setContext } = useContext(DataContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!context?.group) {

      const fetchData = async (code: string) => {
        const data = await getDataForForm(code)

        if (data?.group) {
          setContext(data) // authentication
        }
      };

      const code = searchParams.get('code');
      if (code) fetchData(code);
    }
  }, []);

  return !context?.group ? <Login /> : <AttendView />
}

export default Attend
