import { css } from '@emotion/css'
import appSettings from '../../app/app-settings'

const allGuestsCss = {
  table: () => css({
    borderCollapse: "collapse",
  }),

  headerRow: () => css({
    border: "1px solid black",
    padding: 8,
  }),
  
  row: (isOdd: boolean, textAlign?: "center") => css({
    background: isOdd ? appSettings.colors.blueColor : undefined,
    color: isOdd ? appSettings.colors.whiteColor : undefined,
    border: "1px solid black",
    padding: 8,
    textAlign: textAlign ?? "left",
  }),
}

export default allGuestsCss
