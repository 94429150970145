import React, { useContext } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import PhotoView from './photo-view'

const Photo: React.FC = () => {
  const { context } = useContext(DataContext);

  return !context?.group ? <Login /> : <PhotoView />
}

export default Photo
