import React, { useContext } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import GiftsView from './gifts-view'

const Gifts: React.FC = () => {
  const { context } = useContext(DataContext);

  return !context?.group ? <Login /> : <GiftsView />
}

export default Gifts
