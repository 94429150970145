import React, { useContext } from 'react'
import Login from '../../components/login/login'
import { DataContext } from '../../app/data-context'
import AllGuestsView from './all-guests-view';

const AllGuests: React.FC = () => {
  const { context } = useContext(DataContext);

  return !context?.group ? <Login /> : <AllGuestsView />
}

export default AllGuests
